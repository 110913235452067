<template>
  <div class="modal-overlay" v-if="showModal">
    <div class="modal-content">
      <!-- <i
        class="pi pi-times"
        style="color: red; cursor: pointer; align-self: flex-end;"
        @click="closeModal"
      ></i> -->
      <h1>Editar dados da Avaliação Imobiliária</h1>

      <!-- <div v-if="loading" class="loading-indicator">
        <i class="pi pi-spinner pi-spin"></i> Carregando...
      </div> -->

      <div v-if="successMessage" class="success-message">
        {{ successMessage }}
      </div>

      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      <form @submit.prevent="submitForm">
        <div class="form-group type-group">
          <div class="form-group-item">
            <label for="fachada">Foto da Fachada</label>
            <input
              type="file"
              @change="handleImageUpload($event, 'fachada')"
              accept="image/png, image/jpeg"
            />
            <div v-if="images.fachada" class="image-preview">
              <img :src="images.fachada" alt="Foto da Fachada" />
            </div>
          </div>
          <div class="form-group-item">
            <label for="localizacao">Foto da Localização</label>
            <input
              type="file"
              @change="handleImageUpload($event, 'localizacao')"
              accept="image/png, image/jpeg"
            />
            <div v-if="images.localizacao" class="image-preview">
              <img :src="images.localizacao" alt="Foto da Localização" />
            </div>
          </div>
        </div>
        <div class="form-group type-group">
          <div>
            <label for="endereco">Nome do requerente</label>
            <input
              type="text"
              id="requerente"
              v-model="form.nome_requerente"
              maxlength="100"
            />
          </div>
          <div>
            <label for="cpf">CPF do requerente</label>
            <input
              type="text"
              id="cpf"
              v-mask="'###.###.###-##'"
              v-model="form.cpf_requerente"
            />
          </div>
          <div>
            <label for="endereco">Endereço</label>
            <input
              type="text"
              id="endereco"
              v-model="form.endereco"
              maxlength="100"
              required
            />
          </div>
        </div>

        <div class="form-group type-group">
          <div>
            <label for="complemento">Complemento</label>
            <input
              type="text"
              id="complemento"
              v-model="form.complemento"
              maxlength="20"
            />
          </div>
          <div>
            <label for="matricula">Matrícula do Imóvel</label>
            <input type="text" id="matricula" v-model="form.matricula" />
          </div>
          <div>
            <label for="empreendimento">Empreendimento</label>
            <input
              type="text"
              id="empreendimento"
              v-model="form.empreendimento"
              maxlength="50"
            />
          </div>
          <div>
            <label for="uso">Uso</label>
            <select id="uso" v-model="form.uso" required>
              <option value="Residencial">Residencial</option>
              <option value="Comercial">Comercial</option>
            </select>
          </div>
          <div>
            <label for="tipologia">Tipologia</label>
            <select id="tipologia" v-model="form.tipologia" required>
              <option value="Casa">Casa</option>
              <option value="Apartamento">Apartamento</option>
              <option value="Sobrado">Sobrado</option>
              <option value="Triplex">Triplex</option>
              <option value="Terreno">Terreno</option>
              <option value="Galpão">Galpão</option>
              <option value="Outros">Outros</option>
            </select>
          </div>
        </div>

        <div class="form-group address-group">
          <div>
            <label for="condominio">Condomínio</label>
            <select id="condominio" v-model="form.condominio" required>
              <option value="Sim">Sim</option>
              <option value="Não">Não</option>
            </select>
          </div>
          <div>
            <label for="andar_pavimento">Andar/Pavimento do Imóvel</label>
            <input
              type="text"
              id="andar_pavimento"
              v-model="form.andar_pavimento"
              maxlength="10"
              min="0"
            />
          </div>
          <div>
            <label for="idade_imovel">Idade do Imóvel</label>
            <input
              type="number"
              id="idade_imovel"
              v-model="form.idade_imovel"
              maxlength="3"
              required
              min="0"
            />
          </div>
          <div>
            <label for="estado_conservacao">Conservação</label>
            <select
              id="estado_conservacao"
              v-model="form.estado_conservacao"
              required
            >
              <option value="Novo">Novo</option>
              <option value="Ótimo">Ótimo</option>
              <option value="Bom">Bom</option>
              <option value="Regular">Regular</option>
              <option value="Ruim">Ruim</option>
            </select>
          </div>
        </div>

        <div class="details-group">
          <div>
            <label for="total_dormitorios">Dormitórios</label>
            <input
              type="number"
              id="total_dormitorios"
              v-model="form.total_dormitorios"
              maxlength="2"
              required
              min="0"
            />
          </div>
          <div>
            <label for="total_bwc">Total BWC</label>
            <input
              type="number"
              id="total_bwc"
              v-model="form.total_bwc"
              maxlength="2"
              min="0"
              required
            />
          </div>
          <div>
            <label for="vagas_garagem">Vagas Garagem</label>
            <input
              type="number"
              id="vagas_garagem"
              v-model="form.vagas_garagem"
              maxlength="2"
              required
              min="0"
            />
          </div>
          <div>
            <label for="valor_avaliacao">Valor da Avaliação</label>
            <input
              type="text"
              v-money="moneyOptions"
              v-model="form.valor_avaliacao"
              id="valor_avaliacao"
            />
          </div>
          <div>
            <label for="area_m2">Área m²</label>
            <input
              type="number"
              step="0.01"
              id="area_m2"
              v-model="form.area_m2"
              maxlength="10"
              min="0"
              @input="calculateValorM2"
            />
          </div>
          <div>
            <label for="valor_m2">Valor do m²</label>

            <input type="text" v-model="form.valor_m2" id="valor_m2" readonly />
          </div>
        </div>
        <div class="button-container">
          <button
            type="button"
            class="cancel-button"
            @click="closeModal"
            :disabled="isSubmitting"
          >
            Cancelar
          </button>
          <button type="submit" class="submit-button" :disabled="isSubmitting">
            {{ isSubmitting ? "Carregando..." : "Salvar e fechar" }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
/* eslint-disable */
export default {
  props: ["reportId"],
  data() {
    return {
      showModal: false,
      form: {
        endereco: "",
        nome_requerente: "",
        cpf_requerente: "",
        complemento: "",
        matricula: "",
        empreendimento: "",
        uso: "Residencial",
        tipologia: "Casa",
        condominio: "Não",
        andar_pavimento: "",
        idade_imovel: "",
        estado_conservacao: "Bom",
        total_dormitorios: "",
        total_bwc: "",
        vagas_garagem: "",
        valor_avaliacao: "",
        area_m2: "",
        valor_m2: "",
        fiscal_id: null,
      },
      moneyOptions: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        masked: false,
      },
      images: {
        localizacao: null,
        fachada: null,
      },
      isSubmitting: false,
      loading: false,
      successMessage: null,
      errorMessage: null,
    };
  },

  watch: {
    reportId: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== null) {
          this.loadReportData(newVal);
          this.showModal = true;
        }
      },
    },
    "form.valor_avaliacao": "calculateValorM2",
    "form.area_m2": "calculateValorM2",
  },
  computed: {
    formattedValorAvaliacao: {
      get() {
        return this.formatCurrency(this.form.valor_avaliacao);
      },
      set(value) {
        this.form.valor_avaliacao = this.parseCurrency(value);
        this.calculateValorM2();
      },
    },
    formattedValorM2: {
      get() {
        return this.formatCurrency(this.form.valor_m2);
      },
      set(value) {
        this.form.valor_m2 = this.parseCurrency(value);
      },
    },
  },
  methods: {
    async loadReportData(reportId) {
      try {
        const laudoResponse = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/api/laudo/${reportId}`
        );
        if (!laudoResponse.ok) {
          throw new Error("Erro ao buscar os dados do laudo");
        }
        const laudoData = await laudoResponse.json();

        this.form = {
          endereco: laudoData.endereco || "",
          nome_requerente: laudoData.nome_requerente || "",
          cpf_requerente: laudoData.cpf_requerente || "",
          complemento: laudoData.complemento || "",
          matricula: laudoData.matricula || "",
          empreendimento: laudoData.empreendimento || "",
          uso: laudoData.uso || "",
          tipologia: laudoData.tipologia || "",
          condominio: laudoData.condominio || "",
          andar_pavimento: laudoData.andar_pavimento || "",
          idade_imovel: laudoData.idade_imovel || "",
          estado_conservacao: laudoData.estado_conservacao || "",
          total_dormitorios: laudoData.total_dormitorios || "",
          total_bwc: laudoData.total_bwc || "",
          vagas_garagem: laudoData.vagas_garagem || "",
          valor_avaliacao: laudoData.valor_avaliacao || "",
          area_m2: laudoData.area_m2 || "",
          valor_m2: laudoData.valor_m2 || null,
          fiscal_id: laudoData.fiscal_id || null,
        };
        this.calculateValorM2();
        console.log("Valor m² calculado:", this.form.valor_m2);

        const imagensResponse = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/api/laudo/${reportId}/imagens`
        );
        if (!imagensResponse.ok) {
          throw new Error("Erro ao buscar as imagens do laudo");
        }
        const imagensData = await imagensResponse.json();

        this.images.fachada = imagensData.url_img_fachada || "";
        this.images.localizacao = imagensData.url_img_localizacao || "";
      } catch (error) {
        console.error("Erro ao carregar os dados do laudo:", error);
      }
    },

    formatCurrency(value) {
      if (isNaN(value)) return "";
      let number = parseFloat(value).toFixed(2).replace(".", ",");
      let [integerPart, decimalPart] = number.split(",");
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return `R$ ${integerPart},${decimalPart}`;
    },

    parseCurrency(value) {
      return parseFloat(value.replace(/[^\d]/g, "")) || 0;
    },
    calculateValorM2() {
      const valorAvaliacaoLimpo = this.form.valor_avaliacao
        .replace(/[R$.\s]/g, "")
        .replace(",", ".");

      const areaM2 = parseFloat(this.form.area_m2);
      const valorAvaliacao = parseFloat(valorAvaliacaoLimpo);

      if (!isNaN(valorAvaliacao) && !isNaN(areaM2) && areaM2 > 0) {
        const valorM2 = valorAvaliacao / areaM2;
        this.form.valor_m2 = valorM2.toFixed(2);
        this.form.valor_m2 = this.formatCurrency(this.form.valor_m2);
        console.log("Valor m² calculado corretamente:", this.form.valor_m2);
      } else {
        this.form.valor_m2 = "";
      }
    },

    handleImageUpload(event, type) {
      const file = event.target.files[0];
      if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
        if (file.size <= 2000000) {
          // 2MB limit
          const reader = new FileReader();
          reader.onload = (e) => {
            this.images[type] = e.target.result;
          };
          reader.readAsDataURL(file);
        } else {
          this.errorMessage = "A imagem deve ter no máximo 2MB.";
        }
      } else {
        this.errorMessage = "Apenas imagens JPEG ou PNG são permitidas.";
      }
    },
    async submitForm() {
      this.isSubmitting = true;

      // Limpeza dos valores antes de enviar
      const cleanValorAvaliacao = this.form.valor_avaliacao
        .replace(/R\$\s?/g, "")
        .replace(/\./g, "")
        .replace(",", ".");
      const cleanValorM2 = this.form.valor_m2
        .replace(/[R$\s]/g, "")
        .replace(/\./g, "")
        .replace(",", ".");

      const payload = {
        ...this.form,
        valor_avaliacao: parseFloat(cleanValorAvaliacao),
        valor_m2: parseFloat(cleanValorM2),
        img_fachada:
          this.images.fachada &&
          !this.images.fachada.startsWith("https://s41-itbi.s3.amazonaws.com/")
            ? this.images.fachada
            : "",
        img_localizacao:
          this.images.localizacao &&
          !this.images.localizacao.startsWith(
            "https://s41-itbi.s3.amazonaws.com/"
          )
            ? this.images.localizacao
            : "",
      };

      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/api/laudo/${this.reportId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        const data = await response.json();

        if (response.status === 200) {
          console.log("Resposta bem-sucedida:", data);
          this.successMessage = "Valor salvo com sucesso!";
          sessionStorage.setItem("laudoID", data.laudoId);
          this.$emit("formSubmitted", data.laudoId);

          console.log("Abrindo modal de sucesso...");
          await Swal.fire({
            title: "Sucesso!",
            text: "Laudo editado com sucesso!",
            icon: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });

          this.closeModal();
          console.log("Modal de sucesso fechado.");
          this.$emit("valorAtualizado");
        } else {
          throw new Error("Erro ao atualizar os dados do laudo");
        }
      } catch (error) {
        console.error("Error:", error);
        await Swal.fire({
          title: "Atenção!",
          text: "Ocorreu um erro ao editar o laudo. Por favor tente novamente.",
          icon: "warning",
          confirmButtonText: "Ok",
          didOpen: () => {
            const confirmButton = Swal.getConfirmButton();
            if (confirmButton) {
              confirmButton.style.cssText = `
            background-color: #FF9364 !important; 
            color: #FAFAFA !important; 
            border: none !important; 
            border-radius: 4px !important;  
            padding: 10px 20px !important;  
            font-size: 16px !important; 
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
            width: 190px !important; 
            height: 46px !important;  
          `;
            }
          },
        });
      } finally {
        this.isSubmitting = false;
      }
    },

    closeModal() {
      this.showModal = false;
      window.location.reload();
    },
  },
};
</script>
<style scoped>
.image-preview {
  margin-top: 10px;
}

.image-preview img {
  max-width: 150px;
  max-height: 150px;
  width: auto;
  height: auto;
  object-fit: cover;
}

.form-container {
  background-image: url("/public/Fundotela.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

h1 {
  font-size: 28px;
  color: #136e66;
  margin-bottom: 20px;
  text-align: left;
  text-align: center;
}

span {
  display: block;
  text-align: left;
  margin-bottom: 20px;
  color: #4f4f4f;
}

form {
  display: grid;
  gap: 20px;
}

.form-group-item {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 15px;
  box-sizing: border-box;
}

input[type="text"],
input[type="number"],
select,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  margin-top: 5px;
  box-sizing: border-box;
}

textarea {
  resize: vertical;
  min-height: 80px;
}

label {
  color: #09090b;
}

.images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.images .form-group-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.images input[type="file"] {
  border: none;
  background-color: transparent;
  padding: 10px;
  width: 100%;
  background-color: #f9f9f9;
  cursor: pointer;
}

.images label {
  margin-bottom: 10px;
  font-weight: bold;
}

.address-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
}

.type-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
}

.details-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 8px;
}

.details-group .form-group-item {
  margin: 0;
}

.details-group input[type="text"],
.details-group input[type="number"],
.details-group select,
.details-group textarea {
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
}

.details-group label {
  font-size: 14px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.submit-button,
.cancel-button {
  width: 231px; /* Define a largura dos botões */
  height: 40px; /* Define a altura dos botões */
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
}

.submit-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
  opacity: 0.8;
}

.cancel-button {
  width: 231px;
  height: 40px;
  background-color: #ffffff;
  color: #0e0101;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
}

.cancel-button:hover:not(:disabled) {
  opacity: 0.8;
}

.cancel-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 20px;
  width: 85%;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-content i.pi-times {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 32px;
  color: #333;
}

.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.success-message,
.error-message {
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
}

.success-message {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 32px;
}

.submit-button,
.cancel-button {
  width: 231px;
  height: 40px;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
}

.submit-button {
  background-color: #136e66;
  color: #fff;
}

.submit-button:hover:not(:disabled) {
  opacity: 0.8;
}

.cancel-button {
  background-color: #ffffff;
  color: #0e0101;
}

.cancel-button:hover:not(:disabled) {
  opacity: 0.8;
}

.submit-button:disabled,
.cancel-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
</style>
