<template>
  <div class="table-container">
    <div class="controls">
      <input
        type="text"
        id="input-main"
        v-model="cep"
        @input="validateNumber"
        :maxlength="8"
        placeholder="Digite o CEP"
      />
      <button
        class="toggle-button"
        :disabled="!isCEPValid"
        @click="searchByCEP"
      >
        Buscar por CEP
      </button>
      <input
        type="text"
        v-model="filterValue"
        placeholder="Digite para filtrar"
        class="search-input"
      />
      <!-- <label for="columns">Exibir Colunas:</label>
      <button class="toggle-button" @click="selectAllColumns">Selecionar Todas</button>
      <button class="toggle-button" @click="unselectAllColumns">Desmarcar Todas</button>
      <button class="toggle-button" @click="toggleColumnOptions">Opções</button> -->
      <select
        v-show="showColumnOptions"
        v-model="selectedColumns"
        @change="updateDisplayedColumns"
        id="columns"
        multiple
      >
        <option v-for="column in columns" :key="column.key" :value="column.key">
          {{ column.label }}
        </option>
      </select>
      <ExportButton :data="selectedItems" />
    </div>
    <div v-if="isLoading" id="loading">
      <div class="spinner">Carregando...</div>
    </div>

    <table class="styled-table" v-show="!isLoading">
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              @change="toggleSelectAll"
              :checked="allSelected"
            />
          </th>
          <th
            v-for="column in displayedColumns"
            :key="column.key"
            class="table-header"
            @click="sortBy(column.key)"
          >
            {{ column.label }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="item in filteredItems" :key="item.index">
          <td>
            <input type="checkbox" v-model="selectedIds" :value="item.index" />
          </td>
          <td v-for="column in displayedColumns" :key="column.key">
            <template v-if="column.key === 'area'">
              {{ formatArea(item[column.key]) }}
            </template>
            <template v-else-if="column.key === 'price'">
              R${{ formatCurrency(item[column.key]) }},00
            </template>
            <template v-else-if="column.key === 'Preço por (m²)'">
              R${{ calcularPrecoPorM2(item.area, item.price) }}
            </template>
            <template v-else-if="column.key === 'link'">
              <a :href="item[column.key]" target="_blank">Abrir link</a>
            </template>
            <template v-else>
              {{ item[column.key] }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <div id="loading"></div>
  </div>
</template>


<script>
import ExportButton from "../components/ExportButton.vue";
import Swal from "sweetalert2";

const estadosBrasil = { AC: "Acre", AL: "Alagoas", AP: "Amapá", AM: "Amazonas", BA: "Bahia", CE: "Ceará", DF: "Distrito Federal", ES: "Espírito Santo", GO: "Goiás", MA: "Maranhão", MT: "Mato Grosso", MS: "Mato Grosso do Sul", MG: "Minas Gerais", PA: "Pará", PB: "Paraíba", PR: "Paraná", PE: "Pernambuco", PI: "Piauí", RJ: "Rio de Janeiro", RN: "Rio Grande do Norte", RS: "Rio Grande do Sul", RO: "Rondônia", RR: "Roraima", SC: "Santa Catarina", SP: "São Paulo", SE: "Sergipe", TO: "Tocantins" };

export default {
  name: "TableComponent",
  components: {
    ExportButton,
  },
  data() {
    const columns = [
      { key: "index", label: "N° Ordem" }, { key: "address", label: "Endereço" }, { key: "neighbor", label: "Bairro" }, { key: "area", label: "Área (m²)" }, { key: "room", label: "Quartos" },
      { key: "bath", label: "Banheiros" }, { key: "park", label: "Vagas" }, { key: "price", label: "Preço" }, { key: "Preço por (m²)", label: "Preço por (m²)" }, { key: "link", label: "Link" },
    ];

    return {
      filterValue: "",
      sortKey: "",
      sortOrder: 1,
      selectedIds: [],
      selectedColumns: columns.map((column) => column.key),
      columns: columns,
      tableItems: [],
      showColumnOptions: false,
      valueCEP: "",
      isCEPValid: false,
      cep: "",
    };
  },
  computed: {
    filteredItems() {
      return this.tableItems
        .filter((item) => {
          return Object.values(item).some((value) => {
            return (
              typeof value === "string" &&
              value.toLowerCase().includes(this.filterValue.toLowerCase())
            );
          });
        })
        .sort((a, b) => {
          if (a[this.sortKey] < b[this.sortKey]) return -1 * this.sortOrder;
          if (a[this.sortKey] > b[this.sortKey]) return 1 * this.sortOrder;
          return 0;
        });
    },
    selectedItems() {
      return this.filteredItems.filter((item) =>
        this.selectedIds.includes(item.index)
      );
    },
    allSelected() {
      return (
        this.filteredItems.length > 0 &&
        this.selectedIds.length === this.filteredItems.length
      );
    },
    displayedColumns() {
      return this.columns.filter((column) =>
        this.selectedColumns.includes(column.key)
      );
    },
  },

  methods: {
    fetchTableItems(estado, cidade, bairro) {
      this.toggleLoading("block");
      fetch(
        `${process.env.VUE_APP_API_BASE_URL}/api/items?estado=${estado}&cidade=${cidade}&bairro=${bairro}`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Erro ao buscar os dados da API");
          }
          return response.json();
        })
        .then((data) => {
          this.tableItems = data;
          this.toggleLoading("none");
        })
        .catch((error) => {
          console.error("Erro ao buscar os dados da API:", error);
          this.tableItems = [];
          this.toggleLoading("none");
          Swal.fire({
            icon: "error",
            title: "Erro!",
            text: "Erro ao buscar os dados da API. Por favor, tente novamente.",
          });
        });
    },

    searchByCEP() {
      this.tableItems = [];
      this.toggleLoading("block");

      var cep = document.getElementById("input-main").value;

      fetch(`https://viacep.com.br/ws/${cep}/json`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Erro ao buscar os dados da API");
          }
          return response.json();
        })
        .then((data) => {
          var estado = this.sanitize_string(estadosBrasil[data["uf"]]);
          var cidade = this.sanitize_string(data["localidade"]);
          var bairro = this.sanitize_string(data["bairro"]);

          this.fetchTableItems(estado, cidade, bairro);
        })
        .catch((error) => {
          console.error("Erro ao buscar os dados da API:", error);
          this.tableItems = [];
          this.toggleLoading("none");
          Swal.fire({
            icon: "error",
            title: "Erro!",
            text: "Erro ao buscar os dados da API. Por favor, verifique o CEP e tente novamente.",
          });
        });
    },
    validateNumber(event) {
      this.isCEPValid = this.cep.length === 8 && /^\d+$/.test(this.cep);
      const regex = /^[0-9]*$/;
      if (!regex.test(event.target.value)) {
        event.target.value = this.valueCEP;
      } else {
        this.valueCEP = event.target.value;
      }
    },

    sortBy(key) {
      if (this.sortKey === key) {
        this.sortOrder *= -1;
      } else {
        this.sortKey = key;
        this.sortOrder = 1;
      }
    },
    toggleSelectAll(event) {
      if (event.target.checked) {
        this.selectedIds = this.filteredItems.map((item) => item.index);
      } else {
        this.selectedIds = [];
      }
    },
    updateDisplayedColumns() {
      this.displayedColumns = this.columns.filter((column) =>
        this.selectedColumns.includes(column.key)
      );
    },
    toggleColumnOptions() {
      this.showColumnOptions = !this.showColumnOptions;
    },
    selectAllColumns() {
      this.selectedColumns = this.columns.map((column) => column.key);
    },
    unselectAllColumns() {
      this.selectedColumns = [];
    },
    sanitize_string(str) {
      let treatedStr = str.toLowerCase();
      treatedStr = treatedStr.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      treatedStr = treatedStr.replace(/[^a-z0-9\s]/g, "");
      treatedStr = treatedStr.replace(/\s+/g, "-");
      return treatedStr;
    },
    formatCurrency(amount) {
      let formattedAmount = amount.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
      if (formattedAmount.includes(",")) {
        let parts = formattedAmount.split(",");
        parts[0] = parts[0]
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(",");
      } else {
        formattedAmount = formattedAmount
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return formattedAmount;
      }
    },
    formatArea(area) {
      return area.toLocaleString("pt-BR") + " m²";
    },
    toggleLoading(option) {
      var loading = document.getElementById("loading");
      loading.style.display = option;
    },

    updateSelectionStatus() {
      this.allSelected =
        this.filteredItems.length > 0 &&
        this.selectedIds.length === this.filteredItems.length;
    },
    calcularPrecoPorM2(area, price) {
      const areaNum = parseFloat(area);
      const priceNum = parseFloat(price);

      if (!isNaN(areaNum) && !isNaN(priceNum) && areaNum > 0) {
        return ` ${(priceNum / areaNum).toFixed(2).replace('.', ',')}`; 
      }
      return "R$ 0,00";
    },

  },
};
</script>
<style scoped>
.table-container {
  padding: 16px;
}
.controls {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 20px;
}
.search-input {
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  flex-grow: 1;
  max-width: auto;
}

.toggle-button {
  padding: 8px 16px;
  background-color: #3b8070;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: #2c7463;
}

.search-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex-grow: 1;
}

.column-selector {
  margin-left: auto;
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column-selector select {
  max-width: 200px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
}

.styled-table th,
.styled-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.styled-table th {
  background-color: #ecfcfb;
  color: #136e66;
  border-top: 1px solid #ddd;
  font-weight: bold;
}

.styled-table th:last-child,
.styled-table td:last-child {
  border-right: none;
}

.styled-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.styled-table tr:hover {
  background-color: #f1f1f1;
}

.styled-table a {
  color: #42b983;
  text-decoration: none;
}

.styled-table a:hover {
  text-decoration: underline;
}

.styled-table th,
.styled-table td {
  padding: 16px 8px;
  font-size: 14px;
}

.styled-table th,
.styled-table td {
  vertical-align: middle;
}

button {
  margin: 5px;
}
.toggle-button:disabled {
  background-color: #136e66;
  color: #ffffff;
  cursor: not-allowed;
}

.toggle-button:disabled:hover {
  background-color: #4b8d7e;
}
#loading {
  width: 100%;
  height: 500px;
  background: url("../assets/loading.gif") no-repeat center center;
  background-size: 5%;
  display: none;
}
#input-main {
  width: 100%;
  max-width: 300px;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
  margin: 10px 0;
}

#input-main:focus {
  border-color: #3b8070;
  box-shadow: 0 0 5px rgba(59, 128, 112, 0.5);
  outline: none;
}
</style>
